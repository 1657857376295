@import url(https://use.typekit.net/vis0ncs.css);
/* PARTIALS */
/* COLOUR PALETTE */
/* VARIABLES */
/* MIXINS */
/* MEDIA QUERIES */
/* MAIN DECLARATIONS */
*, *:before, *:after {
  box-sizing: border-box;
  position: relative; }

html {
  font-size: 62.5%; }

html, body {
  height: 100%; }

body {
  margin: 0;
  color: #21262B;
  background-color: #DFE2E6;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased; }

a img {
  outline: none; }

img {
  border: 0; }

figure {
  margin: 0; }

:focus {
  outline: none; }

::selection {
  background: #FF8A41;
  color: #FFFFFF;
  text-shadow: none; }

/* VARIABLES */
/* Detect  */
/* SETTINGS */
body {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-display: swap;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    body {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    body {
      font-size: 1.6rem; } }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1.4rem;
  margin-top: 0; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-weight: 400;
    opacity: .6; }

h1 {
  line-height: 1.3; }
  @media screen and (min-width: 46em) {
    h1 {
      font-size: 2.76rem; } }
  @media screen and (max-width: 45.99em) {
    h1 {
      font-size: 2.37rem; } }

h2 {
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    h2 {
      font-size: 2.3rem; } }
  @media screen and (max-width: 45.99em) {
    h2 {
      font-size: 2.07rem; } }

h3 {
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    h3 {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    h3 {
      font-size: 1.82rem; } }

h4 {
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    h4 {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    h4 {
      font-size: 1.6rem; } }

h5 {
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    h5 {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    h5 {
      font-size: 1.4rem; } }

h6 {
  text-transform: uppercase;
  letter-spacing: .2rem;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    h6 {
      font-size: 1.11rem; } }
  @media screen and (max-width: 45.99em) {
    h6 {
      font-size: 1.23rem; } }

p {
  line-height: 1.5;
  margin-top: 1.4rem;
  margin-bottom: 0; }
  @media screen and (min-width: 46em) {
    p {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    p {
      font-size: 1.6rem; } }
  p > h1 {
    margin-top: 2.8rem; }
  p > h2 {
    margin-top: 2.8rem; }
  p > h3 {
    margin-top: 2.8rem; }
  p > h4 {
    margin-top: 2.1rem; }
  p > h5 {
    margin-top: 2.1rem; }
  p > h6 {
    margin-top: 1.4rem; }

a {
  color: #0488E2;
  outline: none;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.u-flexbox__row {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap; }

.u-flexbox__column {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column; }

/* UTILITIES CLASSES */
h1.u-title {
  margin-top: 0;
  margin-bottom: 4.2rem; }

h2.u-title, h3.u-title, h4.u-title, h5.u-title, h6.u-title {
  margin-top: 0;
  margin-bottom: 2.8rem; }

.u-title--form {
  margin-top: 0;
  margin-bottom: 1.4rem; }

.u-margin-reset {
  margin: 0; }

.u-subtitle {
  margin-top: 0;
  margin-bottom: 2.8rem; }

.u-section-space {
  padding-top: 2.8rem;
  margin-top: 2.8rem;
  border-top: 1px solid #CFD4DA; }

.u-clear {
  clear: both; }
  .u-clear:before, .u-clear:after {
    content: " ";
    display: table; }

.u-center-block {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.u-center-absolute {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.u-left {
  float: left; }

.u-right {
  float: right; }

.u-absolut {
  position: absolute; }

.u-text-left {
  text-align: left; }

.u-text-center {
  text-align: center; }

.u-text-right, .o-table-pagination li:last-child {
  text-align: right; }

.u-uppercase, .o-table--zebra th, .o-table--clean th, .o-table--list th {
  text-transform: uppercase; }

.u-hidden {
  display: none; }

.u-circle, .u-circle__small {
  border-radius: 50%;
  display: block; }

.u-circle__small {
  width: 1.44rem;
  height: 1.44rem; }

.u-inline, .o-table-pagination li, .u-inline--middle, .o-card__nav li, .o-layout__filters li {
  display: inline-block; }

.u-inline, .o-table-pagination li {
  vertical-align: top; }

.u-inline--middle, .o-card__nav li, .o-layout__filters li {
  vertical-align: middle; }

/* CHECKOUT EXAMPLE 

.container {
  -ms-grid-columns: 1fr 35.6rem;
  grid-template-columns: 1fr 35.6rem;
}

.main-info {
  @include grid-child(1,2,1,1);
}

.sidebar {
  @include grid-child(3,4,1,1);
}

<div class="container grid-parent">
  <div class="main-info grid-child">
    ...
  </div>
  <div class="sidebar grid-child">
    ...
  </div>
</div>

*/
.u-wrapper40, .u-wrapper50, .u-wrapper60, .u-wrapper70, .u-wrapper80, .u-wrapper100, .u-wrapper110, .u-wrapper120, .u-wrapper140, .u-wrapper40r, .u-wrapper50r, .u-wrapper60r, .u-wrapper70r, .u-wrapper80r, .u-wrapper100r, .u-wrapper110r, .u-wrapper120r, .u-wrapper140r {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.u-wrapper40 {
  width: 40rem; }

.u-wrapper50 {
  width: 50rem; }

.u-wrapper60 {
  width: 60rem; }

.u-wrapper70 {
  width: 70rem; }

.u-wrapper80 {
  width: 80rem; }

.u-wrapper100 {
  width: 100rem; }

.u-wrapper110 {
  width: 110rem; }

.u-wrapper120 {
  width: 120rem; }

.u-wrapper140 {
  width: 140rem; }

.u-wrapper40r, .u-wrapper50r, .u-wrapper60r, .u-wrapper70r, .u-wrapper80r, .u-wrapper100r, .u-wrapper110r, .u-wrapper120r, .u-wrapper140r {
  width: 95%; }

.u-wrapper40r {
  max-width: 40rem; }

.u-wrapper50r {
  max-width: 50rem; }

.u-wrapper60r {
  max-width: 60rem; }

.u-wrapper70r {
  max-width: 70rem; }

.u-wrapper80r {
  max-width: 80rem; }

.u-wrapper100r {
  max-width: 100rem; }

.u-wrapper110r {
  max-width: 110rem; }

.u-wrapper120r {
  max-width: 120rem; }

.u-wrapper140r {
  max-width: 140rem; }

.o-static-pages .u-wrapper {
  max-width: 96rem;
  width: 80%; }

.o-welcome-box .u-wrapper {
  max-width: 55rem;
  width: 80%; }

/** FORMATING COLUMNS */
.u-col-space, .u-row--grid [class*='u-col'] {
  padding-left: 1.4rem;
  padding-right: 1.4rem; }

.u-row--col:before, .u-row--col:after, .u-row--grid:before, .u-row--grid:after {
  display: table;
  content: ""; }

.u-row--col:after, .u-row--grid:after {
  clear: both; }

.u-row--col {
  width: 100%; }

.u-row--grid {
  margin-left: -1.4rem;
  margin-right: -1.4rem; }

[class*='u-col'] {
  float: left; }

.u-col1 {
  width: 8.33333%; }

.u-col2 {
  width: 16.66667%; }

.u-col3 {
  width: 25%; }

.u-col4 {
  width: 33.33333%; }

.u-col5 {
  width: 41.66667%; }

.u-col6 {
  width: 50%; }

.u-col7 {
  width: 58.33333%; }

.u-col8 {
  width: 66.66667%; }

.u-col9 {
  width: 75%; }

.u-col10 {
  width: 83.33333%; }

.u-col11 {
  width: 91.66667%; }

.u-col12 {
  width: 100%; }

/* Push class are applied in the same .u-col_ class */
.u-push1 {
  margin-right: 8.33333%; }

.u-push2 {
  margin-right: 16.66667%; }

.u-push3 {
  margin-right: 25%; }

.u-push4 {
  margin-right: 33.33333%; }

.u-push5 {
  margin-right: 41.66667%; }

.u-push6 {
  margin-right: 50%; }

.u-push7 {
  margin-right: 58.33333%; }

.u-push8 {
  margin-right: 66.66667%; }

.u-push9 {
  margin-right: 75%; }

.u-push10 {
  margin-right: 83.33333%; }

.u-push11 {
  margin-right: 91.66667%; }

/* BUTTON SIZES */
.u-button--small {
  padding: 0.35rem;
  line-height: 1.5;
  border: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem; }
  @media screen and (min-width: 46em) {
    .u-button--small {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .u-button--small {
      font-size: 1.4rem; } }
  .u-button--small:hover {
    text-decoration: none; }

.u-button--base {
  padding: 0.9rem 1.4rem;
  line-height: 1.5;
  border: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem; }
  @media screen and (min-width: 46em) {
    .u-button--base {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    .u-button--base {
      font-size: 1.6rem; } }
  .u-button--base:hover {
    text-decoration: none; }
  @media screen and (max-width: 45.99em) {
    .u-button--base {
      width: 100%; } }

.u-button--big {
  padding: 1.4rem;
  line-height: 1.5;
  border: none;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem; }
  @media screen and (min-width: 46em) {
    .u-button--big {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    .u-button--big {
      font-size: 1.82rem; } }
  .u-button--big:hover {
    text-decoration: none; }
  @media screen and (max-width: 45.99em) {
    .u-button--big {
      width: 100%; } }

.u-button--block {
  width: 100%; }

button.u-button--block, input.u-button--block {
  width: 100%; }

/* MAIN BUTTON STYLES */
.u-button--primary {
  background: #FF8A41;
  color: #FFFFFF; }
  .u-button--primary:hover {
    color: #FFFFFF;
    background: #EA6614; }

.u-button--secondary {
  color: #FFFFFF;
  background: #035F9D; }
  .u-button--secondary:hover {
    color: #FFFFFF;
    background: #014979; }

.u-button--inactive {
  background: #C0C6CD;
  color: #FFFFFF;
  cursor: default; }

.u-button--outline {
  background: none;
  color: #697886;
  border: 1px solid;
  border-color: #697886; }
  .u-button--outline:hover {
    color: #354350; }

.u-button--link {
  color: #697886;
  background: none; }
  .u-button--link:hover {
    text-decoration: underline;
    color: inherit; }

/* ALERTS & ERRORS */
.o-block {
  width: 43.4rem;
  height: 54.6rem;
  padding: 4.2rem;
  margin: 1.4rem auto; }

.o-block--is-white {
  background: #FFFFFF; }

.o-block--is-violet {
  background: #3D49CC; }

.o-block__badge--is-big {
  width: 23.8rem;
  margin-bottom: 5.6rem; }

.o-block__badge--is-small {
  width: 14.4rem;
  margin-bottom: 4.2rem; }

.o-block__text {
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.4rem; }

.o-block__button {
  width: 100%;
  position: absolute;
  bottom: 4.2rem;
  left: 0; }

/* BOLETIA CARDS MAIN STYLES */
.o-card {
  background: #FFFFFF;
  margin-bottom: 1.4rem;
  display: flex;
  box-shadow: 0px 2px 4px #C0C6CD; }

.o-card--is-list {
  background: none;
  box-shadow: none;
  border-bottom: 1px solid #CFD4DA;
  margin-bottom: 0; }
  .o-card--is-list .o-card__content {
    width: 55.4rem;
    padding-left: 0;
    padding-right: 0; }
  .o-card--is-list .o-card__actions {
    padding-right: 0; }

.o-card__content {
  padding: 2.8rem; }

.o-card__tag {
  color: #3D49CC;
  letter-spacing: .2rem;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0; }
  .o-card__tag + .o-card__title {
    margin-top: 0.35rem; }

.o-card__title {
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.7rem;
  max-width: 47rem;
  padding-right: 2.8rem; }
  .o-card__title.is-short-name {
    width: 40rem; }
  .o-card__title a {
    color: #0488E2; }

.o-card__text {
  color: #697886; }
  .o-card__text + .o-card__nav {
    margin-top: 2.8rem; }

.o-card__price {
  line-height: 1.5;
  color: #C0C6CD;
  font-weight: 400;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-card__price {
      font-size: 2.3rem; } }
  @media screen and (max-width: 45.99em) {
    .o-card__price {
      font-size: 2.07rem; } }

.o-card__nav {
  margin-top: 1.4rem;
  margin-bottom: 0;
  padding-left: 0; }
  .o-card__nav li {
    list-style-type: none;
    margin-right: 2.8rem;
    text-transform: uppercase;
    letter-spacing: .2rem;
    line-height: 1.5; }
    @media screen and (min-width: 46em) {
      .o-card__nav li {
        font-size: 1.33rem; } }
    @media screen and (max-width: 45.99em) {
      .o-card__nav li {
        font-size: 1.4rem; } }

/*** CARD ACTIONS ***/
.o-card__actions {
  position: absolute;
  top: 0;
  right: 0;
  display: table;
  padding: 2.8rem; }

.o-card__data, .o-card__total {
  margin: 0;
  text-align: right;
  font-weight: 400; }

.o-card__data {
  color: #3D49CC; }

.o-card__total {
  color: #7D87DB; }

.o-card__caption {
  text-transform: uppercase;
  letter-spacing: .2rem;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-card__caption {
      font-size: 1.11rem; } }
  @media screen and (max-width: 45.99em) {
    .o-card__caption {
      font-size: 1.23rem; } }

.o-card__buttons {
  text-align: right;
  display: block;
  margin-top: 1.4rem; }

.o-card__single-button {
  margin-left: 1.4rem;
  line-height: 1.5;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .2rem;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-card__single-button {
      font-size: 1.11rem; } }
  @media screen and (max-width: 45.99em) {
    .o-card__single-button {
      font-size: 1.23rem; } }
  .o-card__single-button.is-active {
    background: #3CB59A; }
    .o-card__single-button.is-active:hover span {
      display: none; }
    .o-card__single-button.is-active:hover:after {
      content: "Desactivar"; }
  .o-card__single-button.is-inactive {
    background: #C0C6CD; }
    .o-card__single-button.is-inactive:hover span {
      display: none; }
    .o-card__single-button.is-inactive:hover:after {
      content: "Activar"; }
  .o-card__single-button.is-active:hover, .o-card__single-button.is-inactive:hover {
    background: #035F9D; }

/*** 
ALERTS & ERRORS 
***/
/*

<div class="o-alert is-notice">
  Mi mensaje de alerta incluye opciones:

  <ul class="o-alert__list">
    <li>..</li>
  </ul>
</div>

*/
/* MIXINS */
/* CONTENT */
.o-alert {
  padding: 1.4rem;
  margin-top: 2.8rem;
  margin-bottom: 2.8rem;
  border: 1px solid;
  line-height: 1.5;
  border-radius: 0.5rem; }
  @media screen and (min-width: 46em) {
    .o-alert {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    .o-alert {
      font-size: 1.6rem; } }
  .o-alert.is-notice {
    color: #1A2278;
    border-color: #3D49CC;
    background-color: #C8CCF2; }
  .o-alert.is-error {
    color: #79120C;
    border-color: #D53026;
    background-color: #F5C2BE; }
  .o-alert.is-success {
    color: #227D69;
    border-color: #3CB59A;
    background-color: #C2EBE2; }
  .o-alert.is-info {
    color: #00385E;
    border-color: #0488E2;
    background-color: #DDF1FF; }
  .o-alert.is-warning {
    color: #C88300;
    border-color: #FFAE0B;
    background-color: #FFF3DB; }

.o-alert__list {
  margin-bottom: 0.7rem; }

/*** CLOSED TABLE WITH DATA   ***/
.o-table--zebra, .o-table--clean, .o-table--list {
  width: 100%;
  color: #697886;
  border-spacing: 0; }
  .o-table--zebra th, .o-table--clean th, .o-table--list th {
    letter-spacing: .2rem;
    font-weight: normal;
    line-height: 1.5; }
    @media screen and (min-width: 46em) {
      .o-table--zebra th, .o-table--clean th, .o-table--list th {
        font-size: 1.33rem; } }
    @media screen and (max-width: 45.99em) {
      .o-table--zebra th, .o-table--clean th, .o-table--list th {
        font-size: 1.4rem; } }
  .o-table--zebra th, .o-table--clean th, .o-table--list th, .o-table--zebra td, .o-table--clean td, .o-table--list td {
    padding: 1.4rem; }

.o-table--zebra th {
  color: #DFE2E6; }

.o-table--zebra tbody tr:nth-child(odd) {
  background: #F0F1F3; }

.o-table--zebra tbody tr:nth-child(even) {
  background: #DFE2E6; }

.o-table--zebra tbody tr:nth-child(odd) th {
  background: #21262B; }

.o-table--zebra tbody tr:nth-child(even) th {
  background: black; }

.o-table--clean th {
  border-bottom: 2px solid #354350; }

.o-table--clean tbody tr {
  border-top: 1px solid #CFD4DA; }

.o-table--clean tbody tr:nth-child(even) {
  background: none; }

.o-table--list th {
  border-bottom: 1px solid #354350;
  color: #C0C6CD;
  font-weight: bold; }

.o-table--list tbody tr {
  border-top: 1px solid #DFE2E6; }

.o-table--list tbody tr td {
  border-bottom: 1px solid #CFD4DA; }

.o-table--list tbody tr:last-child td {
  border-bottom: none; }

/* DATA TABLE PAGINATION */
.o-table-pagination {
  text-align: center;
  display: table;
  margin: 2rem auto 0; }
  .o-table-pagination li {
    border-top: 2px solid #CFD4DA; }
    .o-table-pagination li.is-selected {
      color: #035F9D;
      border-color: #035F9D;
      padding: 0.7rem 2.1rem;
      line-height: 1; }
      .o-table-pagination li.is-selected a {
        font-weight: bold; }
  .o-table-pagination li a {
    display: block;
    color: #697886;
    line-height: 1;
    text-decoration: none;
    padding: 0.7rem 2.1rem;
    transition: all .2s ease-out; }
    .o-table-pagination li a:hover, .o-table-pagination li a:focus {
      color: #035F9D; }
  .o-table-pagination li:first-child, .o-table-pagination li:last-child {
    border-top: 2px solid #035F9D; }
    .o-table-pagination li:first-child a, .o-table-pagination li:last-child a {
      padding: 0.7rem 0.35rem; }

/* FORM GLOBAL OBJECTS */
::-webkit-input-placeholder {
  color: #697886; }

:-moz-placeholder {
  color: #697886; }

::-moz-placeholder {
  color: #697886; }

:-ms-input-placeholder {
  color: #697886; }

input, button, textarea, label, select {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-display: swap; }

textarea {
  height: 7rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

label {
  cursor: pointer;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    label {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    label {
      font-size: 1.6rem; } }

select {
  width: 100%;
  height: 2.8rem;
  cursor: pointer; }

input[type="text"], input[type="email"], textarea, input[type="date"],
input[type="password"], input[type="search"], input[type="phone"],
input[type="tel"], input[type="number"] {
  padding: .9rem;
  background: #F9FAFA;
  border: 1px solid #8996A2;
  display: block;
  width: 100%;
  margin: 0;
  box-shadow: none;
  font-weight: 400;
  border-radius: 0.3rem;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    input[type="text"], input[type="email"], textarea, input[type="date"],
    input[type="password"], input[type="search"], input[type="phone"],
    input[type="tel"], input[type="number"] {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    input[type="text"], input[type="email"], textarea, input[type="date"],
    input[type="password"], input[type="search"], input[type="phone"],
    input[type="tel"], input[type="number"] {
      font-size: 1.6rem; } }
  input[type="text"]::-webkit-inner-spin-button, input[type="text"]::-webkit-outer-spin-button, input[type="email"]::-webkit-inner-spin-button, input[type="email"]::-webkit-outer-spin-button, textarea::-webkit-inner-spin-button, textarea::-webkit-outer-spin-button, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-outer-spin-button,
  input[type="password"]::-webkit-inner-spin-button,
  input[type="password"]::-webkit-outer-spin-button, input[type="search"]::-webkit-inner-spin-button, input[type="search"]::-webkit-outer-spin-button, input[type="phone"]::-webkit-inner-spin-button, input[type="phone"]::-webkit-outer-spin-button,
  input[type="tel"]::-webkit-inner-spin-button,
  input[type="tel"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; }
  input[type="text"]:focus, input[type="email"]:focus, textarea:focus, input[type="date"]:focus,
  input[type="password"]:focus, input[type="search"]:focus, input[type="phone"]:focus,
  input[type="tel"]:focus, input[type="number"]:focus {
    background: #FFFFFF;
    border-color: #3D49CC;
    transition: all .2s ease-out; }
  input[type="text"].error, input[type="email"].error, textarea.error, input[type="date"].error,
  input[type="password"].error, input[type="search"].error, input[type="phone"].error,
  input[type="tel"].error, input[type="number"].error {
    border-color: #D53026; }

input[type="file"] {
  padding: 1.4rem;
  line-height: 1.2;
  background: none;
  font-weight: 400;
  border: 1px solid #8996A2;
  display: block;
  width: 100%;
  margin: 0;
  box-shadow: none;
  font-weight: 400;
  border-radius: 0.3rem;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    input[type="file"] {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    input[type="file"] {
      font-size: 1.6rem; } }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

input[type="radio"], input[type="checkbox"] {
  margin: 0 0.7rem 0 0; }

input[type="checkbox"] {
  vertical-align: text-top;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    input[type="checkbox"] {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    input[type="checkbox"] {
      font-size: 1.6rem; } }

input[readonly] {
  color: #C0C6CD; }
  input[readonly]:active, input[readonly]:focus, input[readonly]:hover {
    border: 1px solid #CFD4DA; }

/* CUSTOM FORM ELEMENTS */
.u-field, .u-field--small {
  margin-bottom: 2.8rem; }
  .u-field label, .u-field--small label {
    display: block;
    font-weight: bold; }
    .u-field label + input, .u-field--small label + input {
      margin-top: 0.7rem; }

.u-field .u-help {
  margin-bottom: 0.7rem; }

.u-field--small {
  display: flex;
  flex-wrap: wrap;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }
  .u-field--small label, .u-field--small .u-error {
    width: 100%; }
  .u-field--small label {
    margin-bottom: 0.7rem; }
  .u-field--small input {
    max-width: 15rem;
    min-width: 11rem;
    margin-right: 1.4rem;
    -ms-flex-basis: 30%;
    flex-basis: 30%;
    order: 1; }
  .u-field--small .u-help {
    order: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
    -ms-flex-basis: 50%;
    flex-basis: 50%; }
  .u-field--small .u-error {
    order: 3; }

.u-help, .u-error {
  display: block;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .u-help, .u-error {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .u-help, .u-error {
      font-size: 1.4rem; } }

.u-help {
  font-style: italic;
  color: #354350;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .u-help {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .u-help {
      font-size: 1.4rem; } }

.u-error {
  color: #D53026;
  margin-top: 0.7rem; }

.u-radio {
  margin-bottom: 2.8rem; }
  .u-radio li {
    margin-top: 0.7rem;
    display: flex;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline; }

.u-radio__label {
  font-weight: bold; }

/*** SEARCH ***/
/* delete this single search when no necessary */
.o-search {
  margin-bottom: 2.8rem; }

input[type="search"].o-search__input {
  line-height: 1.5;
  padding-top: 1.4rem;
  padding-left: 5.6rem;
  padding-bottom: 1.4rem; }
  @media screen and (min-width: 46em) {
    input[type="search"].o-search__input {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    input[type="search"].o-search__input {
      font-size: 1.82rem; } }

.o-search--default {
  margin-bottom: 2.8rem; }
  .o-search--default .o-search__input {
    line-height: 1.5;
    padding-top: 1.4rem;
    padding-left: 5.6rem;
    padding-bottom: 1.4rem; }
    @media screen and (min-width: 46em) {
      .o-search--default .o-search__input {
        font-size: 1.92rem; } }
    @media screen and (max-width: 45.99em) {
      .o-search--default .o-search__input {
        font-size: 1.82rem; } }

.o-search--small .o-search__input {
  line-height: 1.5;
  padding-top: 0.7rem;
  padding-left: 4.9rem;
  padding-bottom: 0.7rem; }
  @media screen and (min-width: 46em) {
    .o-search--small .o-search__input {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    .o-search--small .o-search__input {
      font-size: 1.82rem; } }

.o-search__icon {
  background-size: 100%;
  width: 2.8rem;
  height: 2.8rem;
  position: absolute;
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%);
  z-index: 1; }

/*** WIZARD HEADER  ***/
.o-layout, .o-layout--shadow {
  margin-top: 7rem;
  margin-bottom: 11.2rem; }

.o-layout--shadow {
  box-shadow: 0px 2px 4px #C0C6CD; }

.o-layout__nav {
  margin-top: 7rem;
  margin-bottom: 1.4rem;
  color: #697886; }
  .o-layout__nav + .o-layout, .o-layout__nav + .o-layout--shadow {
    margin-top: 0; }
  .o-layout__nav a:active {
    color: #697886; }

.o-layout__space, .o-layout__form {
  padding: 4.2rem; }

.o-layout__space {
  background: #F0F1F3; }

.o-layout__form {
  background: #FFFFFF; }

.o-layout__header {
  background: #FFFFFF;
  border-bottom: 1px solid #DFE2E6; }
  .o-layout__header [class*='u-col'] {
    padding: 2.8rem; }
  .o-layout__header [class*='u-col']:first-child {
    border-right: 1px solid #DFE2E6; }

.o-layout__filters {
  padding-left: 0;
  margin-bottom: 2.8rem; }
  .o-layout__filters li {
    list-style-type: none;
    font-weight: bold;
    margin-right: 4.2rem;
    text-transform: uppercase;
    letter-spacing: .2rem; }
    .o-layout__filters li:last-child {
      margin-right: 0; }
  .o-layout__filters .is-active, .o-layout__filters a:hover {
    color: #3CB59A; }

.o-layout__header__links {
  margin-top: 0;
  margin-bottom: 0; }
  .o-layout__header__links li {
    margin-bottom: 1.4rem;
    text-transform: uppercase;
    list-style-type: none;
    letter-spacing: .2rem;
    line-height: 1.5; }
    @media screen and (min-width: 46em) {
      .o-layout__header__links li {
        font-size: 1.33rem; } }
    @media screen and (max-width: 45.99em) {
      .o-layout__header__links li {
        font-size: 1.4rem; } }
    .o-layout__header__links li:last-child {
      margin-bottom: 0; }

/*** ONLY MARGIN TOP LAYOUTS ***/
.u-layout--margin-top {
  margin-top: 7rem;
  margin-bottom: 0; }

/*** EMPTY STATES ***/
.o-clear-state {
  width: 95%;
  max-width: 43.4rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 2px 4px #C0C6CD; }
  .o-clear-state.u-clear-state--margin {
    margin-top: 7rem;
    margin-bottom: 9.8rem; }

.o-badge {
  color: #FFFFFF;
  font-weight: bold;
  letter-spacing: .2rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.7rem;
  margin-right: 1.4rem;
  line-height: 1.5;
  border-radius: 3px; }
  @media screen and (min-width: 46em) {
    .o-badge {
      font-size: 1.11rem; } }
  @media screen and (max-width: 45.99em) {
    .o-badge {
      font-size: 1.23rem; } }
  .o-badge:hover {
    text-decoration: none; }
  .o-badge.is-success {
    background: #3CB59A; }
  .o-badge.is-info {
    background: #035F9D; }
  .o-badge.is-notice {
    background: #3D49CC; }
  .o-badge.is-error {
    background: #A62119; }
  .o-badge.is-warning {
    background: #E49704; }

/* LIST BLOCK */
.o-list, .o-option-list {
  margin: 1.4rem 0 2.8rem;
  padding-left: 0;
  list-style-position: inside; }
  .o-list input, .o-option-list input {
    line-height: 1.5; }
    @media screen and (min-width: 46em) {
      .o-list input, .o-option-list input {
        font-size: 1.92rem; } }
    @media screen and (max-width: 45.99em) {
      .o-list input, .o-option-list input {
        font-size: 1.82rem; } }
  .o-list ul, .o-option-list ul {
    padding-left: 2.8rem;
    margin-top: 0.7rem; }
  .o-list li, .o-option-list li {
    list-style-type: none; }
  .o-list__caption, .o-option-list__caption {
    margin-top: 0;
    margin-bottom: 0.7rem; }

.o-list__single {
  border-bottom: 1px dashed #C0C6CD;
  display: block;
  transition: all .2s ease-out; }

.o-list__title {
  font-weight: bold; }

.o-list__link {
  text-transform: uppercase;
  list-style-type: none;
  letter-spacing: .2rem;
  line-height: 1.5;
  float: right;
  margin-left: 1.4rem;
  text-align: right; }
  @media screen and (min-width: 46em) {
    .o-list__link {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .o-list__link {
      font-size: 1.4rem; } }

.o-option-list__single {
  border-bottom: 1px solid #CFD4DA;
  display: block; }
  .o-option-list__single .o-option-list__single {
    border-bottom: none; }
    .o-option-list__single .o-option-list__single label {
      font-size: 1.6rem;
      line-height: 2.8rem;
      margin-bottom: 0.7rem;
      padding: 0;
      display: block; }
  .o-option-list__single.is-dropdown {
    border-bottom: none; }

.o-list__single, .o-option-list__single label {
  padding: 2.8rem;
  display: block;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-list__single, .o-option-list__single label {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    .o-list__single, .o-option-list__single label {
      font-size: 1.82rem; } }

/* HOW TO USE SWITCH */
/* SWITCH */
.o-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  right: -1.4rem; }
  .o-switch input[type="checkbox"] {
    display: none; }

.o-switch__label {
  display: block;
  overflow: hidden;
  cursor: pointer; }

.o-switch__inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.2s ease-in 0s; }
  .o-switch__inner:before, .o-switch__inner:after {
    float: left;
    width: 50%;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 1.2rem;
    letter-spacing: 0.168rem; }
  .o-switch__inner:before {
    background-color: #3CB59A; }
  .o-switch__inner:after {
    background-color: #C0C6CD;
    text-align: right; }

.o-switch__switch {
  width: 3.2rem;
  margin: .2rem;
  background: #F0F1F3;
  border: 1px solid #FFFFFF;
  position: absolute;
  top: 0;
  right: 8.4rem;
  bottom: 0;
  border-radius: 0.9rem;
  transition: all .2s ease-out; }

.o-switch__cb:checked + .o-switch__label .o-switch__inner {
  margin-left: 0; }

.o-switch__cb:checked + .o-switch__label .o-switch__switch {
  right: 0; }

.o-switch--default {
  width: 12rem; }
  .o-switch--default .o-switch__label {
    border-radius: 1rem; }
  .o-switch--default .o-switch__inner:before, .o-switch--default .o-switch__inner:after {
    height: 2.8rem;
    line-height: 2.8rem; }
  .o-switch--default .o-switch__inner:before {
    padding-left: 1.4rem; }
  .o-switch--default .o-switch__inner:after {
    padding-right: 0.7rem; }
  .o-switch--default .o-switch__switch {
    width: 3.2rem;
    border-radius: 0.9rem; }

.o-switch--big {
  width: 18.6rem; }
  .o-switch--big .o-switch__label {
    border-radius: 1.4rem;
    border: 1px solid #FFFFFF; }
  .o-switch--big .o-switch__inner:before, .o-switch--big .o-switch__inner:after {
    height: 4.2rem;
    line-height: 4.2rem; }
  .o-switch--big .o-switch__inner:before {
    padding-left: 1.4rem; }
  .o-switch--big .o-switch__inner:after {
    padding-right: 1.4rem; }
  .o-switch--big .o-switch__switch {
    width: 7rem;
    right: 11rem;
    border-radius: 1rem; }

.o-switch--small {
  display: inline-block;
  width: 4.2rem;
  height: 2.1rem;
  right: 0; }
  .o-switch--small .o-switch__label {
    border-radius: 1.7rem;
    width: 100%; }
  .o-switch--small .o-switch__inner:before, .o-switch--small .o-switch__inner:after {
    height: 2.1rem;
    content: ""; }
  .o-switch--small .o-switch__switch {
    width: 1.7rem;
    right: 2.1rem;
    border-radius: 50%; }

/*** NUMBER SPINNER */
.o-spinner {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none; }
  @media screen and (min-width: 46em) {
    .o-spinner {
      height: 4.2rem; } }
  @media screen and (max-width: 45.99em) {
    .o-spinner {
      height: 2.8rem; } }

input[type="number"].o-spinner__number {
  float: left;
  padding: 0;
  text-align: center;
  color: #21262B;
  border: 1px solid #3D49CC;
  background: #FFFFFF;
  outline: none;
  -moz-appearance: textfield;
  border-radius: 0;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    input[type="number"].o-spinner__number {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    input[type="number"].o-spinner__number {
      font-size: 1.82rem; } }
  @media screen and (min-width: 46em) {
    input[type="number"].o-spinner__number {
      width: 5.6rem;
      height: 4.2rem; } }
  @media screen and (max-width: 45.99em) {
    input[type="number"].o-spinner__number {
      width: 3.4rem;
      height: 2.8rem; } }
  input[type="number"].o-spinner__number::-webkit-input-placeholder {
    color: #4D5D6C; }
  input[type="number"].o-spinner__number::-ms-input-placeholder {
    color: #4D5D6C; }
  input[type="number"].o-spinner__number::placeholder {
    color: #4D5D6C; }
  input[type="number"].o-spinner__number::-webkit-inner-spin-button, input[type="number"].o-spinner__number::-webkit-outer-spin-button {
    -webkit-appearance: none; }

.o-spinner__sub, .o-spinner__add {
  float: left;
  display: block;
  text-align: center;
  color: #FFFFFF;
  border: 1px solid #3D49CC;
  background: #3D49CC;
  cursor: pointer;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-spinner__sub, .o-spinner__add {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    .o-spinner__sub, .o-spinner__add {
      font-size: 1.82rem; } }
  @media screen and (min-width: 46em) {
    .o-spinner__sub, .o-spinner__add {
      width: 4.2rem;
      height: 4.2rem;
      line-height: 4.2rem; } }
  @media screen and (max-width: 45.99em) {
    .o-spinner__sub, .o-spinner__add {
      width: 2.8rem;
      height: 2.8rem;
      line-height: 2.8rem; } }
  .o-spinner__sub:hover, .o-spinner__sub:focus, .o-spinner__add:hover, .o-spinner__add:focus {
    background: #2733AC;
    color: #FFFFFF; }

.o-spinner__sub {
  border-right: 0;
  border-radius: 3px 0 0 3px; }

.o-spinner__add {
  top: 0;
  border-left: 0;
  border-radius: 0 3px 3px 0; }

/* LAYOUTS */
/* ERROR 404 */
.o-404 {
  background: #2733AC;
  min-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF; }

.o-404__title {
  line-height: .9;
  margin: 0 auto; }
  .o-404__title:after {
    left: 2px;
    text-shadow: -1px 0 red;
    animation: noise-anim 2s infinite linear alternate-reverse; }
  .o-404__title:before {
    left: -2px;
    text-shadow: 1px 0 green;
    animation: noise-anim-2 3s infinite linear alternate-reverse; }
  .o-404__title:before, .o-404__title:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    color: #FFFFFF;
    background: #2733AC;
    overflow: hidden;
    clip: rect(0, 91rem, 0, 0); }

.o-404__subtitle {
  text-transform: uppercase;
  letter-spacing: .2rem; }

.o-404__text {
  line-height: 1.5;
  color: #7D87DB; }
  @media screen and (min-width: 46em) {
    .o-404__text {
      font-size: 2.3rem; } }
  @media screen and (max-width: 45.99em) {
    .o-404__text {
      font-size: 2.07rem; } }
  .o-404__text a {
    color: #FFFFFF;
    text-decoration: underline; }

.o-404__logo {
  background-size: 100% auto;
  width: 12rem;
  height: 4rem;
  margin: 9.8rem auto 0;
  display: block; }

/* ANIMATION KEYFRAMES */
@-webkit-keyframes noise-anim {
  0% {
    clip: rect(24rem, 9999px, 8rem, 0); }
  5% {
    clip: rect(27rem, 9999px, 2rem, 0); }
  10% {
    clip: rect(14rem, 9999px, 33rem, 0); }
  15% {
    clip: rect(18rem, 9999px, 1rem, 0); }
  20% {
    clip: rect(23rem, 9999px, 44rem, 0); }
  25% {
    clip: rect(30rem, 9999px, 43rem, 0); }
  30% {
    clip: rect(30rem, 9999px, 3rem, 0); }
  35% {
    clip: rect(33rem, 9999px, 20rem, 0); }
  40% {
    clip: rect(3rem, 9999px, 35rem, 0); }
  45% {
    clip: rect(36rem, 9999px, 37rem, 0); }
  50% {
    clip: rect(15rem, 9999px, 23rem, 0); }
  55% {
    clip: rect(47rem, 9999px, 30rem, 0); }
  60% {
    clip: rect(17rem, 9999px, 32rem, 0); }
  65% {
    clip: rect(27rem, 9999px, 29rem, 0); }
  70% {
    clip: rect(14rem, 9999px, 26rem, 0); }
  75% {
    clip: rect(43rem, 9999px, 25rem, 0); }
  80% {
    clip: rect(22rem, 9999px, 48rem, 0); }
  85% {
    clip: rect(39rem, 9999px, 23rem, 0); }
  90% {
    clip: rect(4rem, 9999px, 15rem, 0); }
  95% {
    clip: rect(5rem, 9999px, 42rem, 0); }
  100% {
    clip: rect(44rem, 9999px, 28rem, 0); } }
@keyframes noise-anim {
  0% {
    clip: rect(24rem, 9999px, 8rem, 0); }
  5% {
    clip: rect(27rem, 9999px, 2rem, 0); }
  10% {
    clip: rect(14rem, 9999px, 33rem, 0); }
  15% {
    clip: rect(18rem, 9999px, 1rem, 0); }
  20% {
    clip: rect(23rem, 9999px, 44rem, 0); }
  25% {
    clip: rect(30rem, 9999px, 43rem, 0); }
  30% {
    clip: rect(30rem, 9999px, 3rem, 0); }
  35% {
    clip: rect(33rem, 9999px, 20rem, 0); }
  40% {
    clip: rect(3rem, 9999px, 35rem, 0); }
  45% {
    clip: rect(36rem, 9999px, 37rem, 0); }
  50% {
    clip: rect(15rem, 9999px, 23rem, 0); }
  55% {
    clip: rect(47rem, 9999px, 30rem, 0); }
  60% {
    clip: rect(17rem, 9999px, 32rem, 0); }
  65% {
    clip: rect(27rem, 9999px, 29rem, 0); }
  70% {
    clip: rect(14rem, 9999px, 26rem, 0); }
  75% {
    clip: rect(43rem, 9999px, 25rem, 0); }
  80% {
    clip: rect(22rem, 9999px, 48rem, 0); }
  85% {
    clip: rect(39rem, 9999px, 23rem, 0); }
  90% {
    clip: rect(4rem, 9999px, 15rem, 0); }
  95% {
    clip: rect(5rem, 9999px, 42rem, 0); }
  100% {
    clip: rect(44rem, 9999px, 28rem, 0); } }

@-webkit-keyframes noise-anim-2 {
  0% {
    clip: rect(13rem, 9999px, 48rem, 0); }
  5% {
    clip: rect(4rem, 9999px, 14rem, 0); }
  10% {
    clip: rect(43rem, 9999px, 42rem, 0); }
  15% {
    clip: rect(29rem, 9999px, 16rem, 0); }
  20% {
    clip: rect(12rem, 9999px, 30rem, 0); }
  25% {
    clip: rect(29rem, 9999px, 44rem, 0); }
  30% {
    clip: rect(37rem, 9999px, 40rem, 0); }
  35% {
    clip: rect(5rem, 9999px, 4rem, 0); }
  40% {
    clip: rect(47rem, 9999px, 8rem, 0); }
  45% {
    clip: rect(37rem, 9999px, 6rem, 0); }
  50% {
    clip: rect(17rem, 9999px, 41rem, 0); }
  55% {
    clip: rect(30rem, 9999px, 32rem, 0); }
  60% {
    clip: rect(38rem, 9999px, 31rem, 0); }
  65% {
    clip: rect(10rem, 9999px, 23rem, 0); }
  70% {
    clip: rect(40rem, 9999px, 43rem, 0); }
  75% {
    clip: rect(23rem, 9999px, 6rem, 0); }
  80% {
    clip: rect(18rem, 9999px, 9rem, 0); }
  85% {
    clip: rect(14rem, 9999px, 14rem, 0); }
  90% {
    clip: rect(49rem, 9999px, 22rem, 0); }
  95% {
    clip: rect(8rem, 9999px, 1rem, 0); }
  100% {
    clip: rect(2rem, 9999px, 41rem, 0); } }

@keyframes noise-anim-2 {
  0% {
    clip: rect(13rem, 9999px, 48rem, 0); }
  5% {
    clip: rect(4rem, 9999px, 14rem, 0); }
  10% {
    clip: rect(43rem, 9999px, 42rem, 0); }
  15% {
    clip: rect(29rem, 9999px, 16rem, 0); }
  20% {
    clip: rect(12rem, 9999px, 30rem, 0); }
  25% {
    clip: rect(29rem, 9999px, 44rem, 0); }
  30% {
    clip: rect(37rem, 9999px, 40rem, 0); }
  35% {
    clip: rect(5rem, 9999px, 4rem, 0); }
  40% {
    clip: rect(47rem, 9999px, 8rem, 0); }
  45% {
    clip: rect(37rem, 9999px, 6rem, 0); }
  50% {
    clip: rect(17rem, 9999px, 41rem, 0); }
  55% {
    clip: rect(30rem, 9999px, 32rem, 0); }
  60% {
    clip: rect(38rem, 9999px, 31rem, 0); }
  65% {
    clip: rect(10rem, 9999px, 23rem, 0); }
  70% {
    clip: rect(40rem, 9999px, 43rem, 0); }
  75% {
    clip: rect(23rem, 9999px, 6rem, 0); }
  80% {
    clip: rect(18rem, 9999px, 9rem, 0); }
  85% {
    clip: rect(14rem, 9999px, 14rem, 0); }
  90% {
    clip: rect(49rem, 9999px, 22rem, 0); }
  95% {
    clip: rect(8rem, 9999px, 1rem, 0); }
  100% {
    clip: rect(2rem, 9999px, 41rem, 0); } }

@media screen and (max-width: 48em) {
  .o-404 {
    padding-right: 1.4rem;
    padding-left: 1.4rem; }
  .o-404__title {
    font-size: 16rem;
    width: 30rem; }
  .o-404__subtitle {
    margin-top: 1.4rem;
    line-height: 1.5;
    letter-spacing: .2rem; } }
  @media screen and (max-width: 48em) and (min-width: 46em) {
    .o-404__subtitle {
      font-size: 1.92rem; } }
  @media screen and (max-width: 48em) and (max-width: 45.99em) {
    .o-404__subtitle {
      font-size: 1.82rem; } }

@media screen and (max-width: 48em) {
  .o-404__text {
    line-height: 1.5; } }
  @media screen and (max-width: 48em) and (min-width: 46em) {
    .o-404__text {
      font-size: 1.92rem; } }
  @media screen and (max-width: 48em) and (max-width: 45.99em) {
    .o-404__text {
      font-size: 1.82rem; } }

@media screen and (min-width: 48em) and (max-width: 62em) {
  .o-404__title {
    width: 55rem;
    font-size: 30rem; }
  .o-404__subtitle {
    letter-spacing: .3rem; }
  .o-404__text {
    line-height: 1.5; } }
  @media screen and (min-width: 48em) and (max-width: 62em) and (min-width: 46em) {
    .o-404__text {
      font-size: 1.92rem; } }
  @media screen and (min-width: 48em) and (max-width: 62em) and (max-width: 45.99em) {
    .o-404__text {
      font-size: 1.82rem; } }

@media screen and (max-width: 62em) {
  .o-404__subtitle {
    margin-top: 2.8rem; } }

@media screen and (min-width: 62em) {
  .o-404__title {
    font-size: 50rem;
    width: 91rem; } }

/* HEADER */
.o-header {
  z-index: 100; }

.o-header__logo {
  margin-left: 1.4rem;
  margin-right: 1.4rem;
  display: inline-block;
  line-height: 5.6rem; }
  .o-header__logo img {
    height: 3.5rem;
    vertical-align: middle; }

.o-header__link {
  line-height: 5.6rem;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  text-transform: uppercase;
  letter-spacing: .1rem;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-header__link {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .o-header__link {
      font-size: 1.4rem; } }

.o-header__user {
  margin: 0;
  padding-left: 0;
  height: 100%;
  left: -0.4rem; }

.o-header__user__name {
  cursor: pointer;
  list-style: none;
  line-height: 5.6rem;
  padding-left: 2.8rem;
  padding-right: 4.2rem;
  text-transform: uppercase;
  letter-spacing: .2rem;
  transition: all .2s ease-out;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .o-header__user__name {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .o-header__user__name {
      font-size: 1.4rem; } }
  .o-header__user__name:hover {
    background: rgba(0, 0, 0, 0.1); }
  .o-header__user__name .u-arrow-down {
    position: absolute;
    top: 0.6rem;
    right: 1.4rem; }
  .o-header__user__name .dropdown {
    margin-top: 2.8rem; }

.o-header__nav {
  margin: 0 2.8rem 0 0;
  text-align: right;
  display: inline-block;
  padding-left: 0; }
  .o-header__nav li {
    display: inline-block;
    margin-left: 2.8rem; }
    .o-header__nav li a {
      text-transform: uppercase;
      letter-spacing: .2rem;
      line-height: 5.6rem;
      line-height: 1.5; }
      @media screen and (min-width: 46em) {
        .o-header__nav li a {
          font-size: 1.33rem; } }
      @media screen and (max-width: 45.99em) {
        .o-header__nav li a {
          font-size: 1.4rem; } }
    .o-header__nav li .current {
      font-weight: bold; }

.o-header__logo, .o-header__nav, .o-header__link, .o-header__user {
  vertical-align: top; }

/*** HEADER THEMES ***/
.t-header--violet {
  background: #2733AC; }
  .t-header--violet .o-header__nav li a {
    color: #C8CCF2; }
  .t-header--violet .o-header__user {
    border-left: 1px #3D49CC solid; }
  .t-header--violet .o-header__link, .t-header--violet .o-header__account-title, .t-header--violet .o-header__user__name {
    color: #C8CCF2; }

.t-header--dark {
  background: #354350; }
  .t-header--dark .o-header__nav li a {
    color: #8996A2; }
  .t-header--dark .o-header__user {
    border-left: 1px #4D5D6C solid; }
  .t-header--dark .o-header__link, .t-header--dark .o-header__account-title, .t-header--dark .o-header__user__name {
    color: #8996A2; }

.t-header--blue {
  background: #035F9D; }
  .t-header--blue .o-header__nav li a {
    color: #DDF1FF; }
  .t-header--blue .o-header__user {
    border-left: 1px #0488E2 solid; }
  .t-header--blue .o-header__link, .t-header--blue .o-header__account-title, .t-header--blue .o-header__user__name {
    color: #DDF1FF; }

/* ----- MEDIA QUERIES ----- */
@media (max-width: 48rem) {
  .o-header__user__name {
    min-height: 5.6rem; }
  .o-header__nav, .u-user--name {
    display: none; } }

html, body {
  height: auto; }

/* LANGUAGE */
.c-header__links {
  display: flex;
  height: 2.4rem;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.c-header__single {
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.5; }
  @media screen and (min-width: 46em) {
    .c-header__single {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .c-header__single {
      font-size: 1.4rem; } }
  .c-header__single.is-language button {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    display: flex;
    padding-right: 0;
    z-index: 2;
    line-height: 1.5; }
    @media screen and (min-width: 46em) {
      .c-header__single.is-language button {
        font-size: 1.33rem; } }
    @media screen and (max-width: 45.99em) {
      .c-header__single.is-language button {
        font-size: 1.4rem; } }
  .c-header__single.is-language span {
    padding-left: 0.4rem;
    padding-right: 0.4rem; }
  .c-header__single a, .c-header__single button {
    color: #697886;
    text-decoration: none;
    cursor: pointer; }
    .c-header__single a:hover, .c-header__single button:hover {
      color: #354350; }

.c-header__flag {
  line-height: 1.5;
  line-height: 1;
  padding-right: 0; }
  @media screen and (min-width: 46em) {
    .c-header__flag {
      font-size: 1.92rem; } }
  @media screen and (max-width: 45.99em) {
    .c-header__flag {
      font-size: 1.82rem; } }

/* FILTER */
.c-filter__container {
  width: 100%;
  margin-top: 2.8rem;
  margin-bottom: 1.4rem;
  display: flex;
  flex-flow: row wrap; }
  .c-filter__container h4 {
    width: 100%; }
  .c-filter__container.is-super-admin {
    width: 50rem; }
    .c-filter__container.is-super-admin .c-filter {
      margin-right: 2.8rem; }

.c-filter {
  flex: 1 1; }

.c-filter__button {
  border: 1px solid #C0C6CD;
  background: none;
  width: 16.8rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-right: 1.4rem;
  padding-left: 1.4rem;
  margin-right: 1.4rem;
  line-height: 1.3;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  border-radius: 0.3rem;
  transition: all .2s ease-out; }
  @media screen and (min-width: 46em) {
    .c-filter__button {
      font-size: 1.6rem; } }
  @media screen and (max-width: 45.99em) {
    .c-filter__button {
      font-size: 1.6rem; } }
  .c-filter__button:hover {
    background: #DFE2E6; }
  .c-filter__button.is-active {
    background: #3CB59A;
    border-color: #2B9880;
    color: #FFFFFF; }
    .c-filter__button.is-active:hover {
      background: #2B9880;
      border-color: #227D69; }
  .c-filter__button.is-channel, .c-filter__button.is-date {
    width: 22rem; }

.c-filter__actions {
  flex: 0 1 auto; }
  .c-filter__actions .u-button--link {
    margin-right: 1.4rem;
    background: none; }

.c-stats__map, .c-stats__seating {
  width: 100%;
  background: #FFFFFF;
  padding: 2.8rem; }

.c-stats__map {
  margin-bottom: 2.8rem; }

.c-stats__seating {
  margin-top: 2.8rem;
  margin-bottom: 0;
  display: flex; }

.c-stats__seating li {
  color: #4D5D6C;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  list-style-type: none;
  line-height: 1.5;
  flex: 1 1; }
  @media screen and (min-width: 46em) {
    .c-stats__seating li {
      font-size: 1.33rem; } }
  @media screen and (max-width: 45.99em) {
    .c-stats__seating li {
      font-size: 1.4rem; } }

.c-stats__seating strong {
  color: #21262B;
  display: block;
  margin-top: 0.7rem;
  font-size: 2.88rem;
  line-height: 1.3; }
  @media screen and (min-width: 46em) {
    .c-stats__seating strong {
      font-size: 3.31rem; } }
  @media screen and (max-width: 45.99em) {
    .c-stats__seating strong {
      font-size: 2.7rem; } }

/* RESULTS */
.c-results {
  background: #FFFFFF;
  border: 1px solid #CFD4DA;
  box-shadow: 0.1rem 0.1rem 0.4rem #C0C6CD;
  padding: 2.8rem; }
  .c-results:after, .c-results:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid; }
  .c-results:after {
    top: -2rem;
    border-color: transparent transparent #FFFFFF transparent;
    border-width: 1rem; }
  .c-results:before {
    top: -2.4rem;
    margin-left: -2px;
    border-color: transparent transparent transparent;
    border-width: 1.2rem; }
  .c-results.is-users:after, .c-results.is-users:before {
    left: 9.4rem; }
  .c-results.is-users th:nth-child(1) {
    width: 1.4rem; }
  .c-results.is-users th:nth-child(2) {
    width: auto; }
  .c-results.is-users th:nth-child(3) {
    width: 36.8rem; }
  .c-results.is-users th:nth-child(4) {
    width: 20rem; }
  .c-results.is-users td:last-child, .c-results.is-users th:last-child {
    text-align: right; }
  .c-results.is-events:after, .c-results.is-events:before {
    left: 30.8rem; }
  .c-results.is-events th:nth-child(1) {
    width: 1.4rem; }
  .c-results.is-events th:nth-child(2) {
    width: 10rem; }
  .c-results.is-events th:nth-child(3) {
    width: auto; }
  .c-results.is-events th:nth-child(4) {
    width: 10rem; }
  .c-results.is-events th:nth-child(5) {
    width: 18rem; }
  .c-results.is-events th:nth-child(6) {
    width: 10rem; }
  .c-results.is-events .c-results__button:nth-last-child, .c-results.is-users .c-results__button:nth-last-child {
    margin-right: 4.2rem; }
  .c-results.is-calendar {
    width: 67.8rem;
    height: 60rem;
    position: absolute;
    z-index: 1; }
    .c-results.is-calendar .c-results__button-group {
      margin-top: 0; }
    .c-results.is-calendar input[type="text"] {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 24px;
      color: #697886;
      background-color: #FFFFFF;
      width: 100%;
      padding: 11px 11px 9px;
      border: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid transparent;
      border-left: 0;
      border-radius: 0; }

.is-global-stats .is-calendar {
  left: 13rem; }
  .is-global-stats .is-calendar:after, .is-global-stats .is-calendar:before {
    left: 52%; }

.is-event-stats .is-calendar {
  left: 0; }
  .is-event-stats .is-calendar:after, .is-event-stats .is-calendar:before {
    left: 44.5%; }

.c-results__button-group {
  margin-top: 2.8rem;
  margin-bottom: 2.8rem; }

.c-results__button {
  border: 1px solid #C0C6CD;
  background: none;
  padding: 0.7rem 1.4rem;
  margin-right: 1.4rem;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1862rem;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: all .2s ease-out; }
  .c-results__button:hover {
    background: #DFE2E6; }
  .c-results__button.is-active {
    background: #C0C6CD;
    border-color: #697886;
    color: #FFFFFF; }
    .c-results__button.is-active:hover {
      background: #697886;
      border-color: #21262B; }

.c-results__button-row {
  margin-top: 2.8rem; }
  .c-results__button-row .u-button--link {
    padding-left: 0;
    margin-right: 1.4rem; }
  .c-results__button-row.is-calendar_button {
    bottom: -31.6rem;
    z-index: 1; }

.c-results__table {
  margin-top: 2.8rem;
  margin-bottom: 1.4rem; }
  .c-results__table th {
    text-align: left;
    padding-top: 0; }
  .c-results__table th:hover {
    color: #697886; }
  .c-results__table th:first-child, .c-results__table td:first-child {
    padding-left: 0; }
  .c-results__table th:first-child, .c-results__table th:last-child, .c-results__table td:first-child, .c-results__table td:last-child {
    padding-right: 0; }

/* PLUGIN STYLES */
.DateRangePicker {
  line-height: 1.4; }
  .DateRangePicker .DayPickerKeyboardShortcuts_buttonReset {
    display: none; }

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px; }

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc; }

.react-bootstrap-table th.sortable {
  cursor: pointer; }

.react-bootstrap-table .dropup .caret, .react-bootstrap-table .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9; }

.react-bootstrap-table .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

/* STATS */
.c-stats__overview {
  color: #FFFFFF;
  background: #3D49CC;
  width: 100%;
  padding: 2.8rem;
  margin-top: 2.8rem;
  margin-bottom: 2.8rem; }

.c-stats__title {
  letter-spacing: 0.2688rem;
  font-size: 4rem;
  margin-bottom: 2.8rem; }

.c-stats__subtitle {
  letter-spacing: 0.1862rem;
  margin-bottom: 0; }

.c-stats__label {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.224rem;
  margin-bottom: 0;
  color: #FFFFFF; }

.c-stats__list {
  padding-top: 2.8rem;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #7D87DB;
  display: flex; }
  .c-stats__list li {
    width: 29.6rem;
    list-style-type: none; }
    .c-stats__list li:nth-child(2) {
      margin-right: 2.8rem;
      margin-left: 2.8rem; }

.c-stats__back-link {
  display: block;
  width: 100%;
  margin-bottom: 1.4rem; }

.c-stats__box {
  width: 100%;
  background: #FFFFFF;
  padding: 2.8rem;
  margin-bottom: 2.8rem; }
  .c-stats__box table {
    width: 100%; }
    .c-stats__box table td:first-child, .c-stats__box table th:first-child {
      padding-left: 0; }
    .c-stats__box table td:last-child, .c-stats__box table th:last-child {
      padding-right: 0; }
    .c-stats__box table th {
      text-align: right;
      padding-top: 0;
      line-height: 1.5; }
      @media screen and (min-width: 46em) {
        .c-stats__box table th {
          font-size: 1.33rem; } }
      @media screen and (max-width: 45.99em) {
        .c-stats__box table th {
          font-size: 1.4rem; } }
      .c-stats__box table th:first-child {
        text-align: left;
        vertical-align: bottom; }
    .c-stats__box table td {
      text-align: right; }
      .c-stats__box table td:first-child {
        max-width: 28.4rem;
        text-align: left;
        color: #21262B;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .c-stats__box table td:last-child {
        color: #3CB59A; }
      .c-stats__box table td:first-child, .c-stats__box table td:last-child {
        font-weight: 700; }

/* EMPTY STATE */
.c-empty {
  background: #FFFFFF;
  width: 50%;
  text-align: center;
  padding: 4.2rem;
  margin: 5.6rem auto; }
  .c-empty p {
    font-size: 1.6rem; }

.c-empty__image {
  width: 14rem;
  height: auto;
  margin-bottom: 2.8rem; }

.c-empty--table {
  border-top: 1px solid #CFD4DA;
  padding-top: 5.6rem;
  padding-bottom: 2.8rem;
  text-align: center; }

.c-loading {
  margin-top: 11.2rem;
  margin-bottom: 11.2rem;
  text-align: center; }
  .c-loading span {
    width: 5.6rem;
    height: 5.6rem;
    display: block;
    margin: 0 auto; }

.c-loading__title {
  margin-top: 1.4rem; }

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-total,
.react-bootstrap-table-pagination .react-bootstrap-table-pagination-subtotal {
  position: absolute;
  top: 9px; }

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-total {
  left: 0; }

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-subtotal {
  left: 114px; }

.react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown {
  margin-left: 5.6rem;
  margin-right: 1.4rem; }

.react-bootstrap-table-pagination div:first-child {
  display: inline-block; }

.react-bootstrap-table-pagination .react-bootstrap-table-page-btns-ul {
  float: right;
  margin-top: 0px; }

.react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
  float: right; }

.react-bootstrap-table-pagination .pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px; }
  .react-bootstrap-table-pagination .pagination > li {
    display: inline; }
    .react-bootstrap-table-pagination .pagination > li:first-child > a, .react-bootstrap-table-pagination .pagination > li:first-child > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .react-bootstrap-table-pagination .pagination > li:last-child > a, .react-bootstrap-table-pagination .pagination > li:last-child > span {
      margin-right: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }

.react-bootstrap-table-pagination .pagination > .active > a, .react-bootstrap-table-pagination .pagination > .active > a:focus, .react-bootstrap-table-pagination .pagination > .active > a:hover, .react-bootstrap-table-pagination .pagination > .active > span, .react-bootstrap-table-pagination .pagination > .active > span:focus, .react-bootstrap-table-pagination .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7; }

.react-bootstrap-table-pagination .pagination > li > a:focus, .react-bootstrap-table-pagination .pagination > li > a:hover, .react-bootstrap-table-pagination .pagination > li > span:focus, .react-bootstrap-table-pagination .pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd; }

.react-bootstrap-table-pagination .pagination > li > a, .react-bootstrap-table-pagination .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }

.react-bootstrap-table-pagination .btn-default.active.focus, .react-bootstrap-table-pagination .btn-default.active:focus, .react-bootstrap-table-pagination .btn-default.active:hover, .react-bootstrap-table-pagination .btn-default:active.focus, .react-bootstrap-table-pagination .btn-default:active:focus, .react-bootstrap-table-pagination .btn-default:active:hover, .react-bootstrap-table-pagination .open > .dropdown-toggle.btn-default.focus, .react-bootstrap-table-pagination .open > .dropdown-toggle.btn-default:focus, .react-bootstrap-table-pagination .open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.react-bootstrap-table-pagination .btn.active.focus, .react-bootstrap-table-pagination .btn.active:focus, .react-bootstrap-table-pagination .btn.focus, .react-bootstrap-table-pagination .btn:active.focus, .react-bootstrap-table-pagination .btn:active:focus, .react-bootstrap-table-pagination .btn:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.react-bootstrap-table-pagination .dropdown-toggle:focus {
  outline: 0; }

.react-bootstrap-table-pagination .btn-default.active, .react-bootstrap-table-pagination .btn-default:active, .react-bootstrap-table-pagination .open > .dropdown-toggle.btn-default {
  background-image: none; }

.react-bootstrap-table-pagination .btn-default.active, .react-bootstrap-table-pagination .btn-default:active, .react-bootstrap-table-pagination .open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.react-bootstrap-table-pagination .btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.react-bootstrap-table-pagination .btn-default.focus, .react-bootstrap-table-pagination .btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c; }

.react-bootstrap-table-pagination .btn.active, .react-bootstrap-table-pagination .btn:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.react-bootstrap-table-pagination .btn.focus, .react-bootstrap-table-pagination .btn:focus, .react-bootstrap-table-pagination .btn:hover {
  color: #333;
  text-decoration: none; }

.react-bootstrap-table-pagination .btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }

.react-bootstrap-table-pagination .btn .caret {
  margin-left: 0; }

.react-bootstrap-table-pagination .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.react-bootstrap-table-pagination .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.react-bootstrap-table-pagination .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .react-bootstrap-table-pagination .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap; }
  .react-bootstrap-table-pagination .dropdown-menu > li > a:focus, .react-bootstrap-table-pagination .dropdown-menu > li > a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5; }

.react-bootstrap-table-pagination .open > .dropdown-menu {
  display: block; }

.react-bootstrap-table-pagination .show {
  display: block !important; }

