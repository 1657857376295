@import '../node_modules/npm_boletia_ui/boletia_ui.scss';
@import url("https://use.typekit.net/vis0ncs.css");

html, body {height: auto} // FIX FOR IFRAME RESIZER

/* LANGUAGE */
.c-header__links {
  display: flex;
  height: 2.4rem;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0;
  margin: 0;
  position: absolute;
  width: 100%;
}

.c-header__single {
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  @include type(-1);

  &.is-language {
    button {
      background: none;
      border: none;
      cursor: pointer;
      line-height: 1.5;
      display: flex;
      padding-right: 0;
      z-index: 2;
      @include type(-1);
    }

    span {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
  }

  a, button { 
    color: color('dark','200');
    text-decoration: none;
    cursor: pointer;

    &:hover {color: color('dark','400');}
  }
}

.c-header__flag {
  @include type(1);
  line-height: 1;
  padding-right: 0;
}


/* FILTER */

.c-filter__container {
  width: 100%;
  margin-top: $base-space * 2;
  margin-bottom: $base-space;
  @include flexbox;
  @include flex-flow(row wrap);

  h4 {width: 100%;}

  &.is-super-admin {
    width: 50rem;

    .c-filter {
      margin-right: $base-space * 2;
    }
  }
}

.c-filter {
  @include flex(1);
}

.c-filter__button {
  border: 1px solid color('light','500');
  background: none;
  width: 16.8rem;
  padding-top: $base-space * .5;
  padding-bottom: $base-space * .5;
  padding-right: $base-space;
  padding-left: $base-space;
  margin-right: $base-space;
  line-height: line-height(3);
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include type(0);
  @include radius(.3rem);
  @include animate;

  &:hover {background: color('light','300');}

  &.is-active {
    background: color('green');
    border-color: color('green','400');
    color: color('light','100');

    &:hover {
      background: color('green','400');
      border-color: color('green','500');
    }
  }

  &.is-channel, &.is-date {width: 22rem;}
}

.c-filter__actions {
  @include flex(0 auto);

  .u-button--link {
    margin-right: $base-space;
    background: none;
  }
}


// SEATING

.c-stats__map, .c-stats__seating {
  width: 100%;
  background: color('light','100');
  padding: $base-space * 2;
}

.c-stats__map {
  margin-bottom: $base-space * 2;
}

.c-stats__seating {
  margin-top: $base-space * 2;
  margin-bottom: 0;
  @include flexbox;
}

.c-stats__seating li {
  color: color('dark','300');
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  list-style-type: none;
  @include type(-1);
  @include flex(1);
}

.c-stats__seating strong {
  color: color('dark','500');
  display: block;
  margin-top: $base-space * .5;
  font-size: 2.88rem;
  @include type(4);
}


/* RESULTS */

.c-results {
  background: color('light','100');
  border: 1px solid color('light','400');
  box-shadow: .1rem .1rem .4rem color('light','500');
  padding: $base-space * 2;

  &:after, &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:after {
    top: -2rem;
    border-color:  transparent transparent color('light','100') transparent;
    border-width: 1rem;
  }

  &:before {
    top: -2.4rem;
    margin-left: -2px;
    border-color: transparent transparent color('gray')  transparent ;
    border-width: 1.2rem;
  }

  &.is-users {
    &:after, &:before {left: 9.4rem;}

    th:nth-child(1) {width: 1.4rem;}
    th:nth-child(2) {width: auto;}
    th:nth-child(3) {width: 36.8rem;}
    th:nth-child(4) {width: 20rem;}

    td,th {
      &:last-child {text-align: right;}
    }
  }

  &.is-events {
    &:after, &:before {left: 30.8rem;}

    th:nth-child(1) {width: 1.4rem;}
    th:nth-child(2) {width: 10rem;}
    th:nth-child(3) {width: auto;}
    th:nth-child(4) {width: 10rem;}
    th:nth-child(5) {width: 18rem;}
    th:nth-child(6) {width: 10rem;}
  }

  &.is-events, &.is-users {
    .c-results__button:nth-last-child() {margin-right: $base-space * 3;}
  }

  &.is-calendar {
    width: 67.8rem;
    height: 60rem;
    position: absolute;
    z-index: 1;

    .c-results__button-group {margin-top: 0}

    input[type="text"] {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 24px;
      color: color('dark','200');
      background-color: color('light','100');
      width: 100%;
      padding: 11px 11px 9px;
      border: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid transparent;
      border-left: 0;
      border-radius: 0;
    }
  }
}

.is-global-stats .is-calendar {
  left: 13rem;

  &:after, &:before {left: 52%;}
}

.is-event-stats .is-calendar {
  left: 0;

  &:after, &:before {left: 44.5%;}
}

.c-results__button-group {
  margin-top: $base-space * 2;
  margin-bottom: $base-space * 2;
}

.c-results__button {
  border: 1px solid color('light','500');
  background: none;
  padding: $base-space * .5 $base-space;
  margin-right: $base-space;
  font-size: 1rem;
  line-height: line-height(2);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: desktop-type(-1) * .14;
  cursor: pointer;
  @include radius(.3rem);
  @include animate;

  &:hover {background: color('light','300');}

  &.is-active {
    background: color('light','500');
    border-color: color('dark','200');
    color: color('light','100');

    &:hover {
      background: color('dark','200');
      border-color: color('dark','500');
    }
  }
}

.c-results__button-row {
  margin-top: $base-space * 2;

  .u-button--link {
    padding-left: 0;
    margin-right: $base-space;
  }

  &.is-calendar_button {
    bottom: -31.6rem;
    z-index: 1;
  }
}

.c-results__table {
  margin-top: $base-space * 2;
  margin-bottom: $base-space;

  th {text-align: left; padding-top: 0;}
  th:hover {color: color('dark','200');}

  th, td {
    &:first-child {padding-left: 0;}
    &:first-child, &:last-child {padding-right: 0;}
  }
}

/* PLUGIN STYLES */

.DateRangePicker {
  line-height: 1.4;

  .DayPickerKeyboardShortcuts_buttonReset {display: none}
}

.react-bootstrap-table {
  th > .react-bootstrap-table-sort-order > .caret {
    margin: 10px 6.5px;
  }

  th .order > .dropup > .caret {
    margin: 10px 0;
    color: #cccccc;
  }

  th.sortable {cursor: pointer;}

  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid\9;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}


/* STATS */

.c-stats__overview {
  color: color('light','100');
  background: color('violet','300');
  width: 100%;
  padding: $base-space * 2;
  margin-top: $base-space * 2;
  margin-bottom: $base-space * 2;
}

.c-stats__title {
  letter-spacing: desktop-type(1) *.14;
  // @include type(4);
  // TO INCLUDE 4rem TYPE SIZE OVER BOLETIA UI
  font-size: 4rem;
  margin-bottom: $base-space * 2;
}

.c-stats__subtitle {
  letter-spacing: desktop-type(-1) *.14;
  margin-bottom: 0;
}

.c-stats__label {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: desktop-type(0) *.14;
  margin-bottom: 0;
  color: color('light','100');
}

.c-stats__list {
  padding-top: $base-space * 2;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid color('violet','200');
  @include flexbox;

  li {
    width: 29.6rem;
    list-style-type: none;

    &:nth-child(2) {
      margin-right: $base-space * 2;
      margin-left: $base-space * 2;
    }
  }
}


.c-stats__back-link {
  display: block;
  width: 100%;
  margin-bottom: $base-space
}

.c-stats__box {
  width: 100%;
  background: color('light','100');
  padding: $base-space * 2;
  margin-bottom: $base-space * 2;

  table {
    width: 100%;

    td, th {
      &:first-child {padding-left: 0}
      &:last-child {padding-right: 0}
    }

    th {
      text-align: right;
      padding-top: 0;
      @include type(-1);

      &:first-child {
        text-align: left;
        vertical-align: bottom;
      }
    }

    td {
      text-align: right;

      &:first-child {
        max-width: 28.4rem;
        text-align: left;
        color: color('dark','500');
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:last-child {color: color('green');}

      &:first-child, &:last-child {font-weight: 700;}
    }
  }
}

/* EMPTY STATE */

.c-empty {
  background: color('light','100');
  width: 50%;
  text-align: center;
  padding: $base-space * 3;
  margin: $base-space * 4 auto;

  p {font-size: 1.6rem}
}


.c-empty__image {
  width: 14rem;
  height: auto;
  margin-bottom: $base-space * 2;
}


.c-empty--table {
  border-top: 1px solid color('light','400');
  padding-top: $base-space * 4;
  padding-bottom: $base-space * 2;
  text-align: center;
}

.c-loading {
  margin-top: $base-space * 8;
  margin-bottom: $base-space * 8;
  text-align: center;

  span {
    width: 5.6rem;
    height: 5.6rem;
    display: block;
    margin: 0 auto;
  }
}

.c-loading__title {
  margin-top: $base-space;
}


// PAGINATION FOR BOOTSTRAP-REACT-TABLE

.react-bootstrap-table-pagination {

  .react-bootstrap-table-pagination-total,
  .react-bootstrap-table-pagination-subtotal {
    position: absolute;
    top: 9px;
  }

  .react-bootstrap-table-pagination-total {left:0;}

  .react-bootstrap-table-pagination-subtotal {left:114px;}

  .react-bs-table-sizePerPage-dropdown {
    margin-left: $base-space * 4;
    margin-right: $base-space;
  }

  div:first-child {display: inline-block;}

  .react-bootstrap-table-page-btns-ul {
    float: right;
    margin-top: 0px;
  }

  .react-bootstrap-table-pagination-list {
    float: right;
  }

  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 0;
    border-radius: 4px;

    & > li {
      display: inline;

      &:first-child > a, &:first-child > span {
        margin-left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child > a, &:last-child > span {
        margin-right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
  }
  .pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus, .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
  }
  .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .dropdown-toggle:focus {
    outline: 0;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    background-image: none;
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .btn-default.focus, .btn-default:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
  }
  .btn.active, .btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  }
  .btn.focus, .btn:focus, .btn:hover {
    color: #333;
    text-decoration: none;
  }

  .btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .btn .caret {
    margin-left: 0;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);

    & >li>a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;
    }

    &>li>a:focus, &>li>a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
  }

  .open>.dropdown-menu {display: block}
  .show {display: block!important}

}
